import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  discount?: string
}

export const Info = memo(function Info({ discount }: Props) {
  if (!discount) {
    return null
  }

  return (
    <Container dial={5} row wrap>
      {discount ? (
        <Item dial={4} row>
          <Label dangerouslySetInnerHTML={{ __html: discount }} />
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 0.75rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    display: block;
    margin-top: 1.875rem;
  }
`

const Item = styled(FlexBox)``

const Label = styled.span`
  position: relative;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  font-size: 0.9375rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.8125rem;
  line-height: 1.1875rem;

  &:before {
    content: '';
    display: inline-block;
    height: 0.0625rem;
    width: 3.75rem;
    vertical-align: middle;
    margin-right: 1rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  b {
    font-weight: bold;
  }
`
