import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Accordions } from './Accordions'
import { Info } from './Info'

export interface Props {
  bookingButtonURL?: string
  description?: string
  discount?: string
  image?: ImageProps
  includes?: string
  languageCode: string
  terms?: string
  title?: string
}

export const Offer = memo(function Offer({
  bookingButtonURL,
  description,
  discount,
  image,
  includes,
  languageCode,
  terms,
  title,
}: Props) {
  return (
    <Container row stretch wrap>
      <LeftSide>
        <Background>
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
        </Background>
      </LeftSide>

      <RightSide dial={1}>
        {title ? <Title>{title}</Title> : null}

        <Info discount={discount} />

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        <Accordion allowZeroExpanded>
          <Accordions
            description={includes}
            index="0"
            languageCode={languageCode}
            title="offer-includes"
          />

          <Accordions
            description={terms}
            index="1"
            languageCode={languageCode}
            title="terms-and-conditions"
          />
        </Accordion>

        {bookingButtonURL ? (
          <CTA
            label={useVocabularyData('book', languageCode)}
            URL={bookingButtonURL}
            rel="noopener"
            target="_blank"
          />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  text-align: left;
  margin-bottom: 4.5rem;

  .accordion {
    width: 100%;
  }
`

const LeftSide = styled.div`
  width: 31%;
  aspect-ratio: 0.8;

  @media (max-width: 1199px) {
    width: 100%;
    aspect-ratio: unset;
  }
`

const Background = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    aspect-ratio: 1.47;
    height: auto;
    min-height: auto;
  }
`

const RightSide = styled(FlexBox)`
  width: 69%;
  padding: 0 0 0 6.944vw;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 1.875rem 0 1.25rem;
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 19px;
  font-weight: 300;
  line-height: 2.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`
