import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  description?: string
  languageCode: string
  offers: OfferProps[]
  title?: string
}

export const OffersList = memo(function OffersList({
  description,
  languageCode,
  offers,
  title,
}: Props) {
  if (offers.length < 1) {
    return (
      <Container>
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        <FadeInUp>
          <Message>
            {useVocabularyData('no-offers-message', languageCode)}
          </Message>
        </FadeInUp>
      </Container>
    )
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      <List>
        {offers.map((item, index) => (
          <FadeInUp initial="hidden">
            <Offer {...item} />
          </FadeInUp>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 12rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin-top: 7.5rem;
  }
`

const List = styled.div`
  margin: 6rem auto 9.75rem;
  padding: 0 10vw;

  @media (max-width: 1199px) {
    margin: 3rem auto 5.625rem;
    padding: 0 1.875rem;

    > div {
      margin-top: 3.75rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`

const Message = styled.div`
  margin-top: 5.625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding: 11.625rem 10.625vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 7.5rem 1.875rem;
  }
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 3vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;
  position: relative;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Description = styled.div`
  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }
`
