import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  description?: string
  index?: string
  isExpanded?: string
  languageCode: string
  title: string
}

export const Accordions = memo(function Accordions({
  description,
  isExpanded,
  index,
  languageCode,
  title,
}: Props) {
  if (!description) {
    return null
  }

  return (
    <Container className={isExpanded ? 'expanded' : undefined} uuid={index}>
      <Head>
        <Title>{useVocabularyData(title, languageCode)}</Title>
      </Head>

      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  position: relative;
  text-align: left;
  margin-bottom: 0.125rem;
  padding: 0 1.5rem;
  &.expanded {
    border-bottom: 0;
    margin-top: 2.5rem;
    pointer-events: none;
  }

  @media (max-width: 1199px) {
    &.expanded {
      margin-top: 1.875rem;
      pointer-events: unset;

      svg {
        display: block;
      }
    }
  }
`

const Head = styled(AccordionItemHeading)``

const Title = styled(AccordionItemButton)`
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.8125rem;
  padding: 0.9375rem 0;
  line-height: 1.1875rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  position: relative;

  &::before {
    width: 0;
    height: 0;
    content: ' ';
    border-style: solid;
    border-width: 0.5vw 0.4vw 0 0.4vw;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 300ms ease-in-out;
    transform: translate3d(0, -50%, 0);
    border-color: ${({ theme }) => theme.colors.variants.neutralDark3}
      transparent transparent transparent;
  }

  @media (max-width: 991px) {
    &::before {
      border-width: 5px 4px 0 4px;
    }
  }

  &[aria-expanded='true'] {
    &::before {
      transform: translate3d(0, -50%, 0) rotate(-180deg);
    }
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.1875rem;
  line-height: 2.125rem;
  overflow: hidden;
`

const Description = styled.div`
  padding-bottom: 1.875rem;

  p {
    margin-block-end: 1em;
  }
  ul {
    li {
      padding: 0 1.875rem 0 0.75rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark3};
        position: absolute;
        top: 0.875rem;
        border-radius: 50%;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    ul {
      li {
        width: 100%;
        padding-right: 0;
      }
    }
  }
`
